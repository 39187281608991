import React, { memo, useContext, Fragment } from 'react';
import { Caption, Header, Panel } from '@sdflc/ui';
import { MenuHeader, FoodMenu, DefaultLayout, PageLoading, BusinessHoursOverlay } from '../components';
import { withProviders } from '../hoc';
import { SquareUpContext } from '../contexts';

const MenuPage = memo((props) => {
  const { menu, loadingData, firstTimeLoading, error } = useContext(SquareUpContext);

  if (firstTimeLoading) {
    return <PageLoading />;
  }

  return (
    <Fragment>
      <DefaultLayout title='School Lunch'>
        <MenuHeader title='School Lunch Order' />
        <Panel padding={'20px'}>
          {error ? <span>{JSON.stringify(error.message)}</span> : null}
          {!error ? (
            <Fragment>
              <FoodMenu
                menu={menu['School Lunch']}
                isLoading={loadingData}
                firstTimeLoading={firstTimeLoading}
                columnsQty={3}
                categoryWidth='100%'
                displayModifiers={false}
              />
            </Fragment>
          ) : null}
        </Panel>
        <BusinessHoursOverlay />
      </DefaultLayout>
    </Fragment>
  );
});

MenuPage.displayName = 'MenuPage';

MenuPage.propTypes = {};

MenuPage.defaultProps = {};

export default withProviders(MenuPage);
